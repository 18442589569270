<template>
  <div class="custom-modal-container">
    <a-modal
      :width="width"
      :title="title"
      :visible="visible"
      @ok="handleOk"
      :okButtonProps="{style:{display:oKshow?'initial':'none'}}"
      @cancel="handleCancel"
      v-bind="$attrs"
    >
      <slot name="content"></slot>

      <template slot="footer">
        <slot name="footer"></slot>
      </template>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'CustomModal',

  components: {},

  props: {
    width:{
      type:[Number,String],
      default:'520px'
    },
    oKshow:{ // 是否显示按钮
      type: Boolean,
      default: true,
    },
    // 标题
    title: {
      type: String,
      required: true,
    },
    close1:{
      type: Boolean,
      // required: true,
      default:true
    },
    // 确定的回调
    okCallback: {
      type: Function,
      required: false,
    },

    // 取消的回调
    cancelCallback: {
      type: Function,
      required: false,
    },
  },

  data() {
    return {
      visible: false,
    }
  },

  methods: {
    // 显示弹窗
    showModal() {
      this.visible = true
    },

    // 关闭弹窗
    closeModal() {
      this.visible = false
    },

    handleOk() {
      this.okCallback && this.okCallback()
    },

    handleCancel() {
      this.cancelCallback && this.cancelCallback()
        if(this.close1){
          this.visible = false
        }
    },
  },
}
</script>

<style scoped lang="less">
</style>
