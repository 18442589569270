<template>
  <!-- centered -->
  <a-modal
    :visible="visible"
    :closable="false"
    :width="500"
    :maskStyle="{ marginLeft: '68px' }"
    wrapClassName="app-lock-wrap"
    v-bind="$attrs"
  >
    <div class="login-text">
      <div class="login-text-title">请输入登录密码</div>
      <div class="login-text-subtitle">
        该应用开启了应用锁，需输入登录密码才能访问
      </div>
    </div>
    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item ref="name" prop="password">
        <a-input-password
          v-model="form.password"
          placeholder="请输入登录密码"
        />
      </a-form-model-item>
    </a-form-model>
    <template slot="footer">
      <a-button
        key="submit"
        type="primary"
        :loading="loading"
        @click="handleOk"
      >
        确定
      </a-button>
    </template>
  </a-modal>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      loading: false,
      form: {
        password: undefined,
      },
      rules: {
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  mounted() {},

  created() {
    // this.checkApplocker();
  },

  computed: {
    appLockFlag() {
      return this.$store.state.appLock.appLockFlag;
    },
  },
  watch: {
    appLockFlag: {
      handler(newVal) {
        if (newVal) {
          this.checkApplocker();
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    async checkApplocker() {
      let parameter = {
        app: "star_pan_web",
      };
      let fd = new URLSearchParams();
      for (var prop in parameter) {
        fd.append(prop, parameter[prop]);
      }
      try {
        const res = await this.$apis.getShowApp(fd, {
          "Content-Type": "application/x-www-form-urlencoded",
        });
        console.log("云文档应用所res", res);
        if (res.code === 509) {
          this.visible = true;
          // store.commit('appLock/setAppLock',false) // 关闭应用锁
        } else {
          this.visible = false;
        }
      } catch (error) {
        this.visible = false;
      }
    },
    handleOk() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (!valid) return;
        try {
          this.loading = true;
          let parameter = {
            app: "star_pan_web",
            pwd: this.form.password,
          };
          let fd = new URLSearchParams();
          for (var prop in parameter) {
            fd.append(prop, parameter[prop]);
          }
          // const { code, message } = await postFormAction('/auth/sys/applocker/open', { app: this.appKey, pwd:this.form.password})
          const { code, message } = await this.$apis.getShowAppPwsWord(fd, {
            "Content-Type": "application/x-www-form-urlencoded",
          });
          if (code === 200) {
            this.$store.commit('appLock/setAppLock',false) // 关闭应用锁
            this.visible = false;
            this.$message.success(message);
            console.log('应用所状态---this.appLockFlag',this.appLockFlag);
            
          }
          this.loading = false;
        } catch (error) {
          this.loading = false;
        }
      });
      // this.loading = true
    },
  },
};
</script>

<style lang="less">
.login-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-title {
    font-size: 18px;
    color: #333333;
    font-weight: 500;
    margin-bottom: 8px;
  }
  &-subtitle {
    color: #7f7f7f;
    margin-bottom: 24px;
  }
}
.app-lock-wrap {
  margin-left: 68px;
}
</style>
