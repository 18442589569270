<template>
  <customModal ref="selectMemberOrDepModal" :title="modalTitleMap[modalType]" :width="756" :okCallback="okCallback"
    :cancelCallback="clean">
    <template #content>
      <div class="body-content">
        <div class="select-container">
          <div class="search-wrap">
            <a-input-search placeholder="请输入搜索" v-model="keyword" @search="handleSearch" />
          </div>

          <div v-show="showSearchWrap" class="search-result-wrap">
            <div class="tip">包含“{{ keyword }}”的搜索结果：</div>
            <!-- <div v-if="lang=='zh-CN'" class="tip">包含“{{ keyword }}”的搜索结果：</div> -->
            <!-- <div v-if="lang=='en-US'" class="tip">Search results containing "{{ keyword }}":</div> -->

            <a-tree v-show="searchTreeData.length" :checked-keys="searchCheckedKeys" checkable defaultExpandAll
              :checkStrictly="isSingle" :selectable="false" :tree-data="searchTreeData" @check="onCheck($event, 4)" />

            <div v-show="!searchTreeData.length" class="empty-data-wrap">
              <div class="empty-data-inner"></div>
              暂无搜索结果
            </div>
          </div>

      
          <template >
                <div class="company-wrap">
                  <span class="company-icon">{{ tenantName.substr(0, 1) }}</span>
                  <div class="company-name">{{ tenantName }}</div>
                </div>
                <a-tree :checked-keys="depCheckedKeys" checkable defaultExpandAll :checkStrictly="isSingle"
                  :selectable="false" :tree-data="depTreeData" @check="onCheck($event, 1)" />
              </template>


        </div>

        <!-- 已选择的 -->
        <div class="selected-container">
          <div class="selected-wrap">
            已选中
            <span class="selected-number">{{ computedSelectedList.length }}</span>{{ modalType === 1 ? '人' : '部门' }}
            <span class="clear" @click="handleClear">清空</span>
          </div>

          <ul class="selected-list-wrap">
            <li class="selected-item" v-for="{ key, title, avatar, icon, checkedKeysNameList } in computedSelectedList"
              :key="key">

              <div class="avatar_box" v-if="avatar">
                <img :src=avatar alt="">
              </div>

              <span v-if="!avatar && modalType === 1" class="selected-icon">{{ icon }}</span>

              <a-icon v-if="key.substr(0, 2) === 'D:'" type="gold" class="titleIcon" />
              <AIcon v-if="key.substr(0, 2) === 'R:'" type="icon-guanlizuguanli-xian" class="titleIcon" />
              <AIcon v-if="key.substr(0, 2) === 'M:'" type="icon-zhiweiguanli-xian" class="titleIcon" />
              <span class="selected-name">{{ title }}</span>
              <a-icon class="seleted-icon-delete" type="close" @click="handleDelete(key, checkedKeysNameList)" />
            </li>
          </ul>
        </div>
      </div>
    </template>
  </customModal>
</template>

<script>
import customModal from '@/components/customModal'
import { cloneDeep } from 'lodash'
import store from '@/store'
import { Tabs } from "ant-design-vue";

// import {
//   getAllDepartment,
//   getJobGroupInfo,
//   getPermissionGroupInfo,
//   searchMemberOrRole,
//   searchDepart
// } from '@/api/selectMemberOrDep'

export default {
  name: 'selectMemberOrDep',

  components: {
    customModal,
    [Tabs.name]: Tabs,
    [Tabs.TabPane.name]: Tabs.TabPane,
  },

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    // 弹窗类型 1是选择成员，2是选择部门或角色
    modalType: {
      type: Number,
      default: 1
    },
    // 单选模式
    isSingle: {
      type: Boolean,
      default: false
    },
    // 隐藏部门
    hideDepart: {
      type: Boolean,
      default: false
    },
    // 隐藏角色
    hideRole: {
      type: Boolean,
      default: false
    },
    isCode: {
      type: Boolean,
      default: false
    },
    // 默认已选中的keys列表
    /* 2022/5/18
      针对key做出调整避免部门/职务/管理组 key冲突
      在部门/职务/管理组 相应对象key前加入D:/P:/R:作区分
    */
    checkedKeysList: {
      type: Array,
      default: () => []
    },
    // 禁用的keys列表
    /* 2022/5/18
      针对key做出调整避免部门/职务/管理组 key冲突
      在部门/职务/管理组 相应对象key前加入D:/P:/R:作区分
    */
    disableKey: {
      type: String,
      default: ''
    },
    // 当前选中值
    value: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      // 弹窗标题
      modalTitleMap: {
        1: i18n.t('home_page.label.select_member'),
        2: i18n.t('home_page.label.select_dep_role')
      },

      // tab
      tabList: [
        {
          id: 1,
          label: i18n.t('home_page.label.department')
        },
        {
          id: 2,
          label: i18n.t('home_page.label.role')
        }
      ],

      // 部门
      depCheckedKeys: [], // 选中节点的key值的集合
      depTreeData: [], // 部门树形结构数据
      // 部门tab-存储部门或成员
      depTreeDataMap: new Map(),

      // 角色-管理组
      manageCheckedKeys: [], // 选中节点的key值的集合
      manageTreeData: [], // 成员树形结构数据
      // 角色tab-存储部门或成员
      roleTreeDataMap: new Map(),
      showManageGroup: true, // 是否显示管理组
      manageLoading: false, // 是否loading
      isManageRequested: false, // 是否已经请求过

      // 角色-职务
      positionCheckedKeys: [], // 选中节点的key值的集合
      positionTreeData: [], // 成员树形结构数据
      // 角色tab-存储部门或成员
      positionTreeDataMap: new Map(),
      showPosition: true, // 是否显示职务
      positionLoading: false, // 是否loading
      isPositionRequested: false, // 是否已经请求过
      tenantName: '企',
      // 搜索
      searchCheckedKeys: [], // 选中节点的key值的集合
      searchTreeData: [], // 成员树形结构数据
      searchTreeDataMap: new Map(),
      showSearchWrap: false, // 是否显示搜索结果
      keyword: '', // 搜索的关键字
      lang: "zh-CN"
    }
  },

  computed: {
    // 获取已经选择的成员或者部门列表
    computedSelectedList() {
      const computedAllCheckUniqueKeys = this.computedAllCheckUniqueKeys.filter(i => !this.checkedKeysList.includes(i))
      console.log("选中的key:", computedAllCheckUniqueKeys)
      const computedAllCheckUniqueMap = this.getAllCheckUniqueMap()
      console.log("所有的map对象:", computedAllCheckUniqueMap)



      const res = computedAllCheckUniqueKeys.reduce((prev, current) => {
        let { title, phone, username, orgCodeTxt, belongDepart, avatar, email } = computedAllCheckUniqueMap.get(current) || {}
        title &&
          prev.push({
            avatar,
            email,
            username,
            key: current,
            title,
            phone, // 手机号
            orgCodeTxt, // 部门名称
            belongDepart, // 部门id
            icon: title.slice(-2),
            checkedKeysNameList: this.getCheckedKeysNameList(current)
          })
        console.log('');
        return prev
      }, [])

      console.log('res--获取已经选择的成员或者部门列表', res);

      return res
    },

    // 获取全部的checkedKeys
    computedAllCheckUniqueKeys() {
      let { depCheckedKeys, manageCheckedKeys, positionCheckedKeys, searchCheckedKeys } = this

      console.log("🚀 ~ file: index.vue:270 ~ computedAllCheckUniqueKeys ~ depCheckedKeys:", depCheckedKeys)

      return [...new Set([...depCheckedKeys, ...manageCheckedKeys, ...positionCheckedKeys, ...searchCheckedKeys])]
    },

    // map名称和checkedKeys的映射
    computedMap2KeysMap() {
      return {
        depTreeDataMap: 'depCheckedKeys',
        roleTreeDataMap: 'manageCheckedKeys',
        positionTreeDataMap: 'positionCheckedKeys',
        searchTreeDataMap: 'searchCheckedKeys'
      }
    },

    /**
     * 树和改树本身checkedKeys名称的映射
     * 1是部门，2是管理组，3是职务，4是搜索
     */
    computedCheck2ValueMap() {
      return {
        1: 'depCheckedKeys',
        2: 'manageCheckedKeys',
        3: 'positionCheckedKeys',
        4: 'searchCheckedKeys'
      }
    },

    /**
     * checkedKeys名称和要分发的树的checkedKeys名称、员工或者部门map的映射
     */
    computedValue2KeysMap() {
      let { depTreeDataMap, roleTreeDataMap, positionTreeDataMap, searchTreeDataMap } = this

      return {
        depCheckedKeys: [
          { keys: 'manageCheckedKeys', map: roleTreeDataMap },
          { keys: 'positionCheckedKeys', map: positionTreeDataMap },
          { keys: 'searchCheckedKeys', map: searchTreeDataMap }
        ],

        manageCheckedKeys: [
          { keys: 'depCheckedKeys', map: depTreeDataMap },
          { keys: 'positionCheckedKeys', map: positionTreeDataMap },
          { keys: 'searchCheckedKeys', map: searchTreeDataMap }
        ],

        positionCheckedKeys: [
          { keys: 'manageCheckedKeys', map: roleTreeDataMap },
          { keys: 'depCheckedKeys', map: depTreeDataMap },
          { keys: 'searchCheckedKeys', map: searchTreeDataMap }
        ],

        searchCheckedKeys: [
          { keys: 'manageCheckedKeys', map: roleTreeDataMap },
          { keys: 'depCheckedKeys', map: depTreeDataMap },
          { keys: 'positionCheckedKeys', map: positionTreeDataMap }
        ]
      }
    },

    //
    computedTabList() {
      const res = []
      !this.hideDepart && res.push(this.tabList[0])
      !this.hideRole && res.push(this.tabList[1])
      return res
    }
  },

  watch: {
    // 如果有搜索关键字，显示搜索结果
    keyword(val) {
      !val && (this.showSearchWrap = false)
    },
    checkedKeysList() {
      console.log('传入的checkedKeysList', this.checkedKeysList);
      this.setDefaultCheckKeys()
    }
  },

  created() {



    this.getAllDepartment()
    this.setDefaultCheckKeys()
    this.$emit(
      'initComplate',
      this.computedSelectedList.map(i => i.key),
      this.computedSelectedList
    )
  },

  // mounted() {
  //   this.$refs.selectMemberOrDepModal.showModal()
  // },

  methods: {
    async show() {
      await this.getAllDepartment()
      if (this.modalType === 2) {
        await this.handleManageGroup()
        await this.handlePosition()
        this.showPosition = true
        this.showManageGroup = true
      }
      this.setDefaultCheckKeys()
      this.$refs.selectMemberOrDepModal.showModal()
    },
    // 设置默认选中成员
    setDefaultCheckKeys() {
      // debugger
      console.log('默认选中成员');
      const { depTreeDataMap, roleTreeDataMap, positionTreeDataMap } = this
      const defaultCheckKeys = [...this.checkedKeysList, ...this.value]
      console.log("默认选中的keys~ defaultCheckKeys:", defaultCheckKeys)



      this.depCheckedKeys = []
      this.manageCheckedKeys = []
      this.positionCheckedKeys = []
      defaultCheckKeys.forEach(key => {
        if (this.modalType === 2) {
          if (key.startsWith('D:')) {
            depTreeDataMap.get(key) && this.depCheckedKeys.push(key)
          } else if (key.startsWith('R:')) {
            roleTreeDataMap.get(key) && this.manageCheckedKeys.push(key)
          } else if (key.startsWith('P:')) {
            positionTreeDataMap.get(key) && this.positionCheckedKeys.push(key)
          }
        } else {
          depTreeDataMap.get(key) && this.depCheckedKeys.push(key)


        }
      })



    },
    // 获取部门列表
    async getAllDepartment() {
      // let st = this.$store.state.user.userTenants.filter(k => k.lastSelect === 1)
      let st = this.$store.state.user.companyList.filter(k => k.lastSelect === 1)
      if (st.length == 1) this.tenantName = st[0].name
      let { success, result } = await this.$apis.getAllDepartment()

      if (success) {
        this.depTreeData = [
          {
            title: '全部',
            key: '0',
            userCount: 0,
            checkable: !this.isSingle,
            children: this.getTreeData(result, 'depTreeDataMap', '0')
          }
        ]
      }
    },

    // 点击管理组
    async handleManageGroup() {
      // debugger
      // 如果已经请求过，不必再请求
      if (this.isManageRequested) {
        this.showPosition = false
        return
      }

      this.manageLoading = true
      // 标记已经请求过
      this.isManageRequested = true

      console.log("点击了管理组:")
      // 获取管理组列表
      // let { success, result } = await this.$apis.getPermissionGroupInfo()
      try {
        // 获取管理组列表
        let { success, result } = await this.$apis.getPermissionGroupInfo()
        if (success) {
          this.manageTreeData = [
            {
              title: i18n.t('common.all'),
              key: '1',
              userCount: 0,
              checkable: !this.isSingle,
              children: this.getTreeData(result, 'roleTreeDataMap', '1')
            }
          ]
          this.showPosition = false

          this.onCheck(this.getSelfCheckedKeys('roleTreeDataMap'), 2, false)
        }
      } catch (error) { }



      this.manageLoading = false
    },

    // 点击职务
    async handlePosition() {
      // 如果已经请求过，不必再请求
      if (this.isPositionRequested) {
        this.showManageGroup = false
        return
      }

      this.positionLoading = true
      // 标记已经请求过
      this.isPositionRequested = true
      try {
        let { success, result } = await this.$apis.getJobGroupInfo()
        if (success) {
          this.positionTreeData = [
            {
              title: '全部',
              key: '2',
              userCount: 0,
              checkable: !this.isSingle,
              children: this.getTreeData(result, 'positionTreeDataMap', '2')
            }
          ]
          this.showManageGroup = false
          this.onCheck(this.getSelfCheckedKeys('positionTreeDataMap'), 3, false)
        }
      } catch (error) { }
      this.positionLoading = false
    },

    // 点击搜索
    async handleSearch(keyword) {
      if (!keyword) return
      try {
        this.showSearchWrap = true
        // 根据modelType/hidenRole区分模糊搜索类型
        if (this.modalType === 2 && this.hideRole) {
          // 选择部门
          let { success, result } = await this.$apis.searchDepart({ keyword })
          if (success) {
            let searchList = result

            this.searchTreeData = searchList
              ? searchList.reduce((prev, current) => {
                prev.push({ title: current.name, key: current[this.isCode ? 'code' : 'id'] })
                this.searchTreeDataMap.set(current[this.isCode ? 'code' : 'id'], {
                  title: current.name,
                  parentKey: []
                })
                return prev
              }, [])
              : []
            this.onCheck(this.getSelfCheckedKeys('searchTreeDataMap'), 4, false)
          }
        } else {
          // 选择成员或者角色
          let { success, result } = await this.$apis.searchMemberOrRole({ keyword })

          if (success) {
            let searchList = result[0]?.members

            const searchTreeList = searchList
              ? searchList.reduce((prev, current) => {
                prev.push({ title: current.name, key: current[this.isCode ? 'code' : 'id'] })
                this.searchTreeDataMap.set(current[this.isCode ? 'code' : 'id'], {
                  title: current.name,
                  parentKey: []
                })
                return prev
              }, [])
              : []

            this.computedSelectedList.forEach(item => {
              const itemIndex = searchTreeList.findIndex(i => i.key === item.key)
              if (itemIndex === -1) {
                searchTreeList.unshift(item)
              }
            })

            this.searchTreeData = searchTreeList

            this.onCheck(this.getSelfCheckedKeys('searchTreeDataMap', true), 4, false)
          }
        }
      } catch (error) { }
    },

    // 获取存在key的checkedKeys名称列表
    getCheckedKeysNameList(key) {
      let { computedMap2KeysMap } = this
      let checkedKeysNameList = []

      for (const map in computedMap2KeysMap) {
        const checkedKeysName = computedMap2KeysMap[map]
        this[map].get(key) && checkedKeysNameList.push(checkedKeysName)
      }
      console.log('获取存在key的checkedKeys名称列表-checkedKeysNameList', checkedKeysNameList);
      return checkedKeysNameList

    },

    // 获取全部的map
    getAllCheckUniqueMap() {
      const { depTreeDataMap, roleTreeDataMap, positionTreeDataMap, searchTreeDataMap } = this
      const res = [depTreeDataMap, roleTreeDataMap, positionTreeDataMap, searchTreeDataMap].reduce((prev, cur) => {
        ;[...cur.entries()].forEach(([key, value]) => {
          if (prev.has(key)) {
            const val = cloneDeep(prev.get(key))
            const curParent = val.parentKey[0] ? val.parentKey[0] + '-' + value.parentKey[0] : value.parentKey[0]
            val.parentKey = [curParent]
            prev.set(key, val)
          } else {
            prev.set(key, value)
          }
        })
        return prev
      }, new Map([]))
      return res
    },

    // 生成树所需要的数据
    getTreeData(treeData, treeMapName, parentKey) {
      let { modalType } = this
      let tree = treeData.reduce((prev, current) => {
        let { title, key, userCount, userList, children, departOrder, groupCode, code, orgCode, id } = current

        key = treeMapName[0].toUpperCase() + ':' + (this.isCode ? groupCode || code || orgCode || key : id)
        // 缓存当前父级的key
        let currentParentKey = parentKey + ':' + key

        // 如果是部门，那存的就是对应树的部门的key值集合
        modalType === 2 && this[treeMapName].set(key, { title, parentKey: [] })

        // 部门下禁用
        const disabled = modalType === 2 && this.disableKey.split(',').includes(key)
        let childrenTreeData = {
          title: `${title}${modalType === 1 ? `(${userCount})` : ''}`,
          key,
          userCount,
          disabled,
          // 单选模式下 部门/管理组/职务 不可选
          checkable: !this.isSingle || (modalType === 2 && (!!departOrder || !!groupCode || !!code)),
          children:
            modalType === 1
              ? Array.isArray(userList)
                ? userList.map(item => {
                  // console.log(item, '9999')
                  let { avatar, email, realname: title, phone, username, orgCodeTxt, belongDepart } = item
                  let childrenKey = item[this.isCode ? 'username' : 'id']
                  // 如果是成员，那存的就是对应树的成员的key值集合
                  let valueMap = this[treeMapName].get(childrenKey)
                  let value = {
                    avatar,
                    email,
                    username,
                    title,
                    phone, // 手机号
                    orgCodeTxt, // 部门名称
                    belongDepart, // 部门id
                    // 缓存子级的所有父级的key值
                    parentKey: valueMap ? [...valueMap.parentKey, currentParentKey] : [currentParentKey]
                  }

                  this[treeMapName].set(childrenKey, value)
                  return {
                    avatar,
                    email,
                    username,
                    title,
                    phone,
                    orgCodeTxt,
                    belongDepart,
                    key: childrenKey,
                    avatar,
                    disabled: this.disableKey.split(',').includes(childrenKey)
                  }
                })
                : []
              : []
        }
        Array.isArray(children) &&
          childrenTreeData.children.push(...this.getTreeData(children, treeMapName, currentParentKey))
        prev.push(childrenTreeData)
        return prev
      }, [])
      return tree
    },

    // 删除已选择的成员或部门
    handleDelete(key, checkedKeysNameList) {
      // debugger
      let { getAllCheckUniqueMap } = this

      // 读取缓存的成员信息
      let parentKeyList = []
      let { parentKey = [] } = getAllCheckUniqueMap().get(key)
      for (let i = 0; i < parentKey.length; i++) {
        const element = parentKey[i]
        parentKeyList.push(...element.split('-'))
      }

      // console.log('之前的--this[checkedKeysName]', this[checkedKeysName]);

      console.log('checkedKeysNameList--循环数据源', checkedKeysNameList);
      for (let i = 0; i < checkedKeysNameList.length; i++) {
        const checkedKeysName = checkedKeysNameList[i]
        console.log('循环里的--key', key)
        console.log('循环里的--i', i);
        this[checkedKeysName] = this[checkedKeysName].filter(


          checkedKey => checkedKey !== key && !parentKeyList.includes(checkedKey)

     
        )
      }

      // console.log('循环里的--checkedKey', checkedKey);
      console.log('key', key);

      console.log('parentKeyList', parentKeyList);
      // console.log('之后的--this[checkedKeysName]', this[checkedKeysName]);

    },

    // 点击复选框触发
    onCheck(checkedKeys, treeType, isDispatch = true) {
      // 获取当前操作的树对应的checkedKeys名称
      const checkedKeysName = this.computedCheck2ValueMap[treeType]
      // 获取要分发的树
      const dispatchTree = this.computedValue2KeysMap[checkedKeysName]
      // 处理单选
      if (this.isSingle) {
        const { checked = [] } = checkedKeys
        this[checkedKeysName] = checked.length ? [checked[checked.length - 1]] : []
        // 是否需要分发
        isDispatch && this.dispatch(dispatchTree, this[checkedKeysName])
      } else {
        // 获取上一次的checkedKeys
        const prevCheckedKeys = this[checkedKeysName]
        // 多选
        // 上一次的checkedKeys和当前的进行比对，返回操作类型及要删除或增加的keys
        const { keys, type } =
          prevCheckedKeys.length < checkedKeys.length
            ? this.handleAddCheckKeys(prevCheckedKeys, checkedKeys)
            : this.handleDelCheckKeys(prevCheckedKeys, checkedKeys)

        // 给当前操作的树的checkedKeys进行赋值
        type === 'del'
          ? this.filterParentKeys(checkedKeysName, checkedKeys, keys)
          : (this[checkedKeysName] = checkedKeys)

        // 是否需要分发
        isDispatch && this.dispatch(dispatchTree, keys, type)
      }
    },

    // 过滤父级的key，如果该树存在相同的key，且父级被选中，取消勾选子级的时候，父级就不会自动勾选掉
    filterParentKeys(checkedKeysName, checkedKeys, keys) {
      let allCheckUniqueMap = this.getAllCheckUniqueMap()
      let parentKeysList = keys.reduce((prev, currentKey) => {
        let { parentKey = [] } = allCheckUniqueMap.get(currentKey) || {}

        for (let i = 0; i < parentKey.length; i++) {
          const element = parentKey[i]
          prev.push(...element.split('-'))
        }

        return prev
      }, [])
      this[checkedKeysName] = checkedKeys.filter(key => !keys.includes(key) && !parentKeysList.includes(key))
    },

    handleAddCheckKeys(prevCheckedKeys, checkedKeys) {
      return { keys: checkedKeys.filter(key => !prevCheckedKeys.includes(key)), type: 'add' }
    },

    handleDelCheckKeys(prevCheckedKeys, checkedKeys) {
      return {
        keys: prevCheckedKeys.filter(key => !checkedKeys.includes(key)),
        type: 'del'
      }
    },

    // 分发
    dispatch(dispatchTree, operateKeys, type) {
      // debugger
      for (let i = 0; i < dispatchTree.length; i++) {
        const { keys, map } = dispatchTree[i]
        // 过滤出要操作的keys与当前分发的树的公共的keys, 因为只有公共的才会发生联动
        const commonKeys = operateKeys.filter(checkedKey => map.get(checkedKey))
        if (this.isSingle) {
          this[keys] = commonKeys
        } else {
          type === 'add'
            ? this.$set(this, keys, [...this[keys], ...commonKeys])
            : commonKeys.length && this.filterParentKeys(keys, this[keys], commonKeys)
        }
      }
    },

    // 清空
    handleClear() {
      let checkedKeysNameList = Object.values(this.computedMap2KeysMap)
      for (let i = 0; i < checkedKeysNameList.length; i++) {
        const checkedKeysName = checkedKeysNameList[i]
        this[checkedKeysName] = this.checkedKeysList
      }
    },

    // 请求数据后获取自身的checkedKeys
    getSelfCheckedKeys(treeDataMapName, notFilter = false) {

      let { depCheckedKeys, manageCheckedKeys, positionCheckedKeys, searchCheckedKeys } = this

      let checkedKeys = [
        ...new Set([...depCheckedKeys, ...manageCheckedKeys, ...positionCheckedKeys, ...searchCheckedKeys])
      ]

      if (!notFilter) {
        checkedKeys = checkedKeys.filter(key => this[treeDataMapName].get(key))
      }

      return checkedKeys
    },

    // 点击确定按钮触发
    okCallback() {
      const value = this.computedSelectedList.map(i => i.key)

      

      this.$emit('change', value, this.computedSelectedList)
      this.$refs.selectMemberOrDepModal.closeModal()
      this.clean()
    },
    clean() {
      this.depCheckedKeys = []
      this.manageCheckedKeys = []
      this.positionCheckedKeys = []
      this.searchCheckedKeys = []
      this.searchTreeData = []
      this.keyword = ''
    }
  }
}
</script>

<style scoped lang="less">
.body-content {
  display: flex;
  justify-content: space-between;
}

.select-container {
  flex: 1;
  border-right: 1px solid #e8e9eb;

  .search-wrap {
    padding-right: 20px;
    margin-bottom: 10px;
  }

  .search-result-wrap {
    .tip {
      margin: 15px 0 10px;
    }

    .ant-tree-switcher-noop {
      display: none;
    }

    .empty-data-wrap {
      text-align: center;
      font-size: 12px;
      color: #999;

      .empty-data-inner {
        margin: 100px auto 7px;
        width: 101px;
        height: 88px;
        background: url('~@/assets/selectDepOrMember/empty-data.png') center top / cover no-repeat;
      }
    }
  }

  .ant-tabs {
    margin: 12px 0 0 0;
  }

  .ant-tabs-tab {
    margin-right: 20px;
    padding: 12px 0;
    color: #999;
  }

  .ant-tabs-tab-active {
    color: #1890ff;
  }

  .ant-tabs-bar {
    padding-right: 20px;
    border: none;

    .ant-tabs-nav-container {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }
  }

  .company-wrap {
    display: flex;
    align-items: center;

    .company-icon {
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      color: #1890ff;
      border-radius: 50%;
      background: #dbeeff;
    }

    .company-name {
      margin-left: 8px;
      font-size: 14px;
      color: #333;
    }
  }

  .ant-tree {
    height: 348px;
    overflow: auto;
  }

  .role-wrap {
    .role-item {
      font-size: 16px;

      .role-item-inner {
        margin-right: 10px;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
      }

      &.manage-group-role {
        margin-bottom: 8px;
      }
    }

    .manage-group,
    .position {
      margin-left: 8px;
      font-size: 14px;
      color: #333;
    }
  }
}

.selected-container {
  padding-left: 19px;
  flex: 1;

  .selected-wrap {
    display: flex;
    padding: 5px 16px;
    border: 1px solid #91d5ff;
    border-radius: 2px;
    background: #e6f7ff;
  }

  .selected-number {
    margin: 0 3px;
  }

  .clear {
    margin-right: 0px;
    margin-left: auto;
    cursor: pointer;
  }

  .selected-number,
  .clear {
    color: #4a61ff;
  }

  .selected-list-wrap {
    height: 360px;
    overflow: auto;

    .selected-item {
      padding: 12px 0;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      cursor: pointer;

      .avatar_box {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        overflow: hidden;
        margin-right: 6px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &:hover {
        background: #fafbfb;

        .j-icon {
          display: flex;
        }
      }
      &:hover {
        .seleted-icon-delete {
          display: block;
        }
      }
    }

    .selected-icon {
      margin-right: 6px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #fff;
      font-size: 12px;
      border-radius: 50%;
      background: #3271ff;
      user-select: none;
    }

    .selected-name {
      color: #333;
    }

    .seleted-icon-delete {
      display: none;
      margin-right: 10px;
      margin-left: auto;

      &:hover {
        color: #f5222d;
        // font-weight: 700;
      }
    }

    .j-icon:not(.titleIcon) {
      flex: 1;
      // display: flex;
      display: none;

      .icon {
        margin-left: auto;
      }
    }
  }
}
</style>
