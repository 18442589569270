
import Vue from "vue";
import AiCreationDocment from "../AiCreationDocment/index.vue";
import showAppPwsWprd from "./showAppPwsWprd.vue";
import AiCreation from "./AiCreation/index.vue";

import PlusModal from "@/components/layouts/MenuComponents/PlusModal.vue";

// import documentTool from "@/views/documentTool/index.vue";
import MydocMenu from "./menu.vue";
import MyDocMenuItem from "./menuItem.vue";
import MydocTabs from "./tabs.vue";
import MenuSimple from "./menuSimple.vue";
import Empty from "@/components/Empty/index.vue";
import FileList from "@/views/recycle/fileList.vue";
import TileList from "./tileList.vue";
import CooperationModal from "./CooperationModal.vue";
import ShareModal from "./ShareModal.vue";
import ShareFileModal from "./ShareFileModal.vue";
import { Radio } from "ant-design-vue";
import Popup from "../popup/index.vue";
import PreviewImg from "@/components/previewImg/index.vue";
import FileDetails from "@/components/FileDetails/index.vue";
// 内容较多, 数据暂存混入文件, 此文件只写模板
import Mixins from "./mixin/index";
interface FileType {
  icon: string;
  title: string;
  extensionName: string;
}
export default Vue.extend({
  name: "mydocIndex",
  mixins: [Mixins],
  components: {
    "a-radio": Radio,
    AiCreationDocment,
    AiCreation,
    MydocMenu,
    MyDocMenuItem,
    MydocTabs,
    MenuSimple,
    Popup,
    Empty,
    FileList,
    TileList,
    PreviewImg,
    FileDetails,
    CooperationModal,
    ShareModal,
    ShareFileModal,
    PlusModal,
    showAppPwsWprd,
  },
  props: {},

  data() {
    return {
      setDocToolParams: {},
      clickOnTheBlankSpaceFlag: 0, // 右键菜单点击了空白处
      parentId: "",
      getParentIdType: 1, // 1 新建文件夹 2 上传文件 3 上传文件夹
      key: 0, // ai文档加载次数
      // 新建数据
      plusData: {
        visible: false,
        addType: "",
        title: "",
        formModel: {
          value: "",
        },
      },
      file_option: [
        {
          label: "下载",
          onClick: () => {
            const that = this as any;
            that.handleClick({ func: "download" });
          },
        },
        {
          label: "复制",
          onClick: () => {
            const that = this as any;
            that.handleClick({ func: "copy" });
          },
        },
        {
          label: "移动",
          onClick: () => {
            const that = this as any;
            that.handleClick({ func: "move" });
          },
        },
        {
          label: "删除",
          onClick: () => {
            const that = this as any;
            that.handleClick({ func: "delete" });
          },
        },
      ],
      UploaderList: [
        {
          icon: "shangchuanwenjian",
          title: "上传文件",
          functionCode: "UPLOAD_LOCAL_FILE",
          emit: (this as any).handleUpload,
        },
        {
          icon: "shangchuanwenjianjia",
          title: "上传文件夹",
          functionCode: "UPLOAD_LOCAL_DIR",
          emit: (this as any).handleUploadFolder,
        },
      ],
      documentToolList: [
        {
          icon: "shangchuanwenjian",
          title: "Office转PDF",
          functionCode: "UPLOAD_LOCAL_FILE",
          emit: (this as any).pdfToWordFn,
        },
        {
          icon: "shangchuanwenjianjia",
          title: "pdf转Office",
          functionCode: "UPLOAD_LOCAL_DIR",
          emit: (this as any).pdfToWordFn,
        },
      ],

      loading: false,
      checkIdListFlag: false,
      indeterminate: false,
      // aicreationFlag: false
    } as any;
  },
  watch: {
    checkIdList(v) {
      this.indeterminate =
        Boolean(v.length) && v.length !== this.listData.length;
      this.checkIdListFlag = Boolean(
        v.length === this.listData.length && this.listData.length
      );
    },
    // checkedFileItemList(val){
    // console.log("选择的文件:", val)
    // },
    "$route.query": {
      handler(v) {
        if (Object.keys(v).length && v.tabIndex) {
          // this.fileWeightPageList  =true
          this.apiName = "fileWeightPageList";
          this.handleClick({
            func: "conversionMode",
            name: "liebiaoshitu",
            text: "列表视图",
            _value: "liebiaoshitu",
          });
          // this.fetchData(v.id,'fileWeightPageList')
        } else if (!Object.keys(v).length) {
          this.apiName = "userFilePageList";

          // this.fetchData(v.id,'userFilePageList')
        }
      },
      deep: true,
    },
    "$store.state.upload.changeType"() {
      this.onPopupClose();
    },
  },
  mounted() {
    let docToolMenuArr = [
      "PDF_Word",
      "Word_PDF",
      "Excel_PDF",
      "PPT_PDF",
      "pdf_edit_addImage",
      "pdf_edit_brush",
      "pdf_edit_signature",
      "pdf_edit_watermark",
    ];

    docToolMenuArr.forEach((eventType) => {
      this.$on(eventType, (el: any) => {
        // this.setDocToolParams=eventType
        console.log("点击的是", this.eventType);
        this.showDocToolByFile(eventType); // 打开文档转换窗口/
      });
    });

    this.$on("clickOnTheBlankSpace", (val: any) => {
      this.clickOnTheBlankSpaceFlag = val;
      console.log("点击了空白处--02", this.clickOnTheBlankSpaceFlag);
    });

    // this.$store.commit("user/setShowAicreationFlag", false);
  },
  beforeDestroy() {
    this.$store.commit("user/setShowAicreationFlag", false);
  },
  computed: {
    // 是否开启了敏感词审核
    isAudit: function () {
      return this.$store.state.user.isAudit;
    },
    aicreationFlag() {
      return this.$store.state.user.showAicreationFlag;
    },
  },
  methods: {
    // 创建书写md文档
    createMdFile() {
      console.log("个人文档创建书写markdown文件");
      this.$router.push({
        name: "MD_EDITOR",
        query: {
          type: "creat",
          teamId: this.$route.query.teamId ? this.$route.query.teamId : null,
          parentId: this.$route.query.id ? this.$route.query.id : "",
        },
      });
    },

    formatUserFilePath(pathStr: any) {
      if (pathStr === "/") {
        return "个人文档";
      }
      // 如果有多个部分，去掉最后一个斜杠，并在第一个斜杠前加上"个人文档"
      const parts = pathStr.split("/");
      parts.shift(); // 去掉第一个空字符串（如果存在）
      return "个人文档/" + parts.join("/");
    },

    showDocToolByFile(eventType: any) {
      console.log("选中的文件为-------", this.checkedFileItemList);
      let filterEditType = [
        "pdf_edit_addImage",
        "pdf_edit_brush",
        "pdf_edit_signature",
        "pdf_edit_watermark",
      ];
      if (filterEditType.includes(eventType)) {
        // alert('编辑功能开发中')
        this.$router.push({
          name: "PDF_EDIT",
          query: {
            id: this.checkedFileItemList[0].id,
            activeMenu: eventType,
            userFileName: encodeURIComponent(
              this.checkedFileItemList[0].userFileName
            ),
          },
        });
        return;
      }

      let tempCheckedFileItemList = this.checkedFileItemList.map(
        (item: any) => {
          return {
            ...item,
            actionFilePath: this.formatUserFilePath(item.userFilePath),
          };
        }
      );

      this.$store.commit("docTool/changeDocToolFrom", "fileMenu"); // 改变文档工具进入来源
      this.$store.commit("docTool/addSelectFiles", tempCheckedFileItemList);
      this.$store.dispatch("docTool/async_setActiveMenu", eventType);
      setTimeout(() => {
        this.$store.dispatch(
          "docTool/async_changeDocTransformDialogFlag",
          true
        ); // 打开文档转换窗口/
      }, 100);
      this.$store.dispatch(
        "docTool/async_setShowDocToolMinimizedFloatingWindow",
        false
      ); // 关闭文档转换最小化悬浮窗口
    },

    getParentId(e: { id: string } | undefined) {
      this.parentId = e?.id;
      if (this.getParentIdType === 1) {
        this.plusData = {
          title: "新建文件夹",
          addType: "folder",
          visible: true,
          formModel: { value: "" },
        };
        return;
      }
      if (this.getParentIdType === 2) {
        let id = e?.id;

        this.$store.commit("upload/SET_UPLOADCLICK", id);
        return;
      }
      if (this.getParentIdType === 3) {
        let id = e?.id;
        this.$store.commit("upload/SET_UPLOADCLICKFOLDER", id);
      }
    },
    onCheckAllChange(e: any) {
      if (e.target.checked) {
        this.checkIdList = this.listData.map((i: any) => i.id);
      } else {
        this.checkIdList = [];
      }
    },
    plusModalClose() {
      this.plusData = (this.$options as any).data().plusData;
    },
    // 点击上传文件
    handleUpload() {
      if (this.$route.query.fileType) {
        this.getParentIdType = 2;
        return this.handleClick({ func: "uploader" });
      }
      let id: string = (this.$route.query.id as string) || "";

      this.$store.commit("upload/SET_UPLOADCLICK", id);
    },
    // 点击上传文件夹
    handleUploadFolder() {
      if (this.$route.query.fileType) {
        this.getParentIdType = 3;
        return this.handleClick({ func: "uploader" });
      }
      let id: string = (this.$route.query.id as string) || "";
      console.log("点击上传文件夹--id", id);
      // this.$store.commit("upload/setUploadClickFolder", id);
      this.$store.commit("upload/SET_UPLOADCLICKFOLDER", id);
    },
    handleAddFile(type: string | FileType) {
      if (this.$route.query.fileType) {
        this.getParentIdType = 1;
        return this.handleClick({ func: "add" });
      }
      if (type === "folder") {
        this.plusData = {
          title: "新建文件夹",
          addType: type,
          visible: true,
          formModel: { value: "" },
        };
      } else {
        this.plusData = {
          title: (type as FileType).title,
          addType: type as any,
          visible: true,
          formModel: { value: "" },
        };
      }
    },
    loadingStatus(val: any) {
      if (val) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    },
    showAicreation() {
      this.$store.commit("user/setShowAicreationFlag", true);
      // this.aicreationFlag=!this.aicreationFlag
      // this.aicreationFlag=true
      // window.sessionStorage.setItem('showAicreationFlag', 'true')
    },
  },
});
