<template>
  <div class="csii-empty">
    <img src="@/assets/images/empty.png" class="empty-img" />
    <p>暂无文档</p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.empty-img {
  width: 164px;
  margin-bottom: 30px;
}
.csii-empty {
  margin: 100px auto;
}
</style>
