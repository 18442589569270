
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
@Component({
  name: "ShareFileModal",
  components: {},
  filters: {
    //局部过滤器
  },
})
export default class ShareFileModal extends Vue {
  public shareName: string = "";

  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop({
    type: Boolean,
    default: false,
  })
  readonly visible!: boolean;
  @Prop({
    type: String,
    default: "public",
  })
  readonly type!: string;
  @Prop({
    type: Array,
  })
  readonly fileList!: any[];
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  //@Ref()
  onClickCancle() {
    this.$emit("close");
  }
  onClickOk() {
    if(this.type === 'public') {
      this.$emit("publicOk", this.shareName);
    } else {
      this.$emit('privateOk', this.shareName)
    }
  }
  //创建前钩子函数
  created(): void {
    this.shareName =
      this.fileList[0].isDir === 0
        ? `【${this.fileList[0].userFileName}.${this.fileList[0].extensionName}】等${this.fileList.length}个文件`
        : `【${this.fileList[0].userFileName}】等${this.fileList.length}个文件`;
  }
}
