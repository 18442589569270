<template>
  <div class="aiCreation">
    <div class="itemBox">
      <div class="item">
        <div class="item_text">AI生成文档</div>
        <a-divider type="vertical" />

        <!-- <a-button @click="ceshiFlag = !ceshiFlag">
          切换
        </a-button> -->
      </div>
      <div class="flex w-full types">
        <div @click="aiCreation(item.label, index)" class="flex_1 " v-for="(item, index) in radioOptions" :key="index">
          <img width="24" :src="item.image" alt="">
          <span :class="aicreationFlag&&index === idx ? 'item_text' : ''">{{ item.label }}</span>
        </div>
      </div>
    </div>

    <!-- <a-button @click="addBtn" type="primary">
      添加成员
    </a-button> -->

    <selectMemberOrDep ref="selectMemberOrDep" :modalType="1" @change="addManager" :value="model.userIds" />

  </div>
</template>
  
<script >

import selectMemberOrDep from '@/components/selectMemberOrDep'
export default {
  name: 'aiCreation',
  components: {
    selectMemberOrDep
  },
  data() {
    return {
      model: {},
      idx: null,
      isShow: false,
      selectType: 1,
      checkedKeysList: [],
      radioOptions: [
        {
          value: '发言稿',
          label: '发言稿',
          modulTitle: '目标群体',
          modulArea: '发言内容',
          placeholderTitle: '请输入，如：师生、员工、亲朋好友',
          placeholderArea: '请简要描述您的发言内容，如：开学致词，感谢同学老师的信任',
          image: require('@/assets/images/Statements.png')
        },
        {
          value: '论文写作',
          label: '论文写作',
          modulTitle: '论文标题',
          modulArea: '论文关键内容',
          placeholderTitle: '请输入，如：人工智能的影响',
          placeholderArea: '请简要描述您论文的关键内容，如：人工智能的发展历程、人工智能的应用',
          image: require('@/assets/images/thesis_writing.png')
        },
        {
          value: '需求文档',
          label: '需求文档',
          modulTitle: '功能名称',
          modulArea: '需求描述',
          placeholderTitle: '请输入，如：会员体系设计',
          placeholderArea: '请简要描述您的需求内容，如：通过会员体系，促进用户增长～',
          image: require('@/assets/images/requirement_document.png')
        },
        {
          value: '产品介绍',
          label: '产品介绍',
          modulTitle: '产品名称',
          modulArea: '产品亮点',
          placeholderTitle: '请输入，如：数智365-Ai文档',
          placeholderArea: '请简要描述您的产品亮点，如：可智能生成word文档、使用类别丰富多样',
          image: require('@/assets/images/product.png')
        },
        {
          value: '推广方案',
          label: '推广方案',
          modulTitle: '需要推广的产品名称',
          modulTitle2: '目标推广人群',
          modulArea: '产品亮点',
          placeholderTitle: '请输入，如：数智365-Ai文档',
          placeholderTitle2: '请输入，如：销售、财务、策划',
          placeholderArea: '请简要描述您的产品亮点，如：可智能生成word文档、使用类别丰富多样',
          image: require('@/assets/images/promotion_scheme.png')
        },
        {
          value: '生成文档',
          label: '生成文档',
          modulTitle: '请输入文档标题',
          modulArea: '文档核心内容',
          placeholderTitle: '请输入文档标题',
          placeholderArea: '请简要描述您想要生成的文档内容～',
          image: require('@/assets/images/Custom_document.png')
        },
        // Add more options as needed

      ],
    }
  },
  created() {


  },
  mounted() {

  },
  watch: {

  },
  computed: {
    // 租户id 企业id
    companyId() {
      return this.$store.state.user.companyId
    },
    token() {
      return this.$store.state.user.token
    },

    aicreationFlag() {

      return this.$store.state.user.showAicreationFlag
    },


  },
  methods: {
    add() {
      this.edit({})
    },
    addManager(userList, rows) {
      this.model.userIds = userList
      this.model.users = rows.map(p => {
        return { userId: p.key, realname: p.title }
      })
    },
    deleteUser(userId) {
      const index = this.model['userIds'].indexOf(userId)
      if (index > -1) {
        this.model['userIds'].splice(index, 1)
      }
    },
    edit(record) {
      this.form.resetFields()
      this.model = Object.assign({}, record)
      if (record.roles) this.model['roleIds'] = record.roles.map(p => p.id).join(',')
      if (record.users) {
        this.model['userIds'] = record.users.map(p => p.userId)
        this.model['users'] = record.users
      }
      this.visible = true
      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model, 'groupName', 'description', 'roleIds'))
      })
    },
    close() {
      this.$emit('close')
      this.visible = false
    },
    aiCreation(value, index) {
      this.$store.commit('user/setAicreationmodulType', value)
      this.idx = index
      this.$emit('showAicreation')
     
      // this.$emit('ceshi', this.ceshiFlag)


      // if (!this.ceshiFlag) {
      //   this.$router.push({
      //     name: 'VIEW_AiCreationDocment'
      //   })
      // }

    },

    // addBtn() {
    //   // this.$refs.selectDepOrPer.show()
    //   this.$refs.selectMemberOrDep.show()


    // },

    // 选择部门或者成员成功之后的回调
    addUser(val, checkKeys) {
      console.log('ssssssssssssssssssssssssssssss')
      if (this.selectType === 2) {
        this.form.deptContainList = checkKeys.map(item => {
          return {
            containId: item.key.split('D:')[1],
            containCode: item.code
          }
        })
        this.department = checkKeys.map(item => item.title).toString()
        return
      }
      this.form.memberContainList = checkKeys.map(item => {
        return {
          containId: item.key,
          containCode: item.username
        }
      })
      this.member = checkKeys.map(item => item.title).toString()
    },

  }
}
</script>
  
<style lang="less" scoped>
.aiCreation {
  // width: 60px;
  width: 100%;
  height: 64px;
  background: #fff;
  font-size: 20px;
  text-align: left;
  // margin-bottom: 24px;
  margin-bottom: 16px;

  .types {
    cursor: pointer;

    img {
      vertical-align: middle;

    }

    span {
      font-size: 14px;
      color: #333;
      font-weight: 400px;
      margin-left: 12px;
    }
  }

  .itemBox {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;

    // background-color: #C3CBCF;
    .item .item_text {
      margin-left: 16px;
      margin-right: auto;
      // font-weight: 700;
    }

    .item {
      cursor: pointer;
      box-sizing: border-box;
      width: 240px;
      height: 100%;
      border-radius: 8px;
      // border: 1px solid #C3CBCF;
      display: flex;
      align-items: center;
      // padding: 17px 34px;
      padding-left: 0;
      padding-right: 42px;
      justify-content: space-between;
      background-color: #fff;

      &:hover {
        // border: 1px solid #3271FF;
      }

      .ant-divider {
        width: 1px;
        height: 28px;
        background: #3271FF;
      }

      .img_box_left {
        width: 24px;
        height: 24px;
        // background-color: #C3CBCF;
        display: flex;
        align-items: center;

        img {
          width: 100%;
          height: 100%;

        }
      }



      .img_box_right {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        // background-color: gray;

        img {
          width: 100%;
          height: 100%;
        }
      }

    }
  }
}

.w-full {
  width: 100%;
}

.item_text {
  font-size: 18px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: bold;
  color: #3271FF !important;
}
</style>
lign-items: center;
        // background-color: gray;

        img {
          width: 100%;
          height: 100%;
        }
      }

    }
  }
}
</style>